import { mapGetters } from 'vuex';
import STATES from '../../../store/actions/states';
import { AUTH_REQUEST } from '../../../store/actions/auth';
import { StringTests, ErrorType, ErrorMessage, ErrorMessages } from '../../../constants';
import RecaptchaForm from '../../../components/mixins/RecaptchaForm';
import { captureException } from '@sentry/vue';

export default {
  name: 'LoginFormHorizontal',

  mixins: [RecaptchaForm],

  props: {
    vertical: {
      type: Boolean,
      default: false,
    },
    submitText: {
      type: String,
      default() {
        return this.vertical ? '$vuetify.continue': '$vuetify.login';
      },
    },

    block: {
      type: Boolean,
    },

    dense: {
      type: Boolean,
    },

    outlined: {
      type: Boolean,
    },

    signUpAction: {
      type: Function,
    },
  },

  data() {
    return {
      valid: true,
      login: process.env.VUE_APP_USERLOGIN,
      emailRules: [
        (v) => !!v || this.$vuetify.lang.t('$vuetify.alerts.login_required'),
        (v) => StringTests.email.test(v) || this.$vuetify.lang.t('$vuetify.alerts.login_not_valid'),
      ],
      password: process.env.VUE_APP_USERPASS,
      passwordRules: [(v) => !!v || this.$vuetify.lang.t('$vuetify.alerts.password_required')],
      showPassword: false,

      disabled: false,

      errorMessages: [],
    };
  },

  computed: {
    ...mapGetters(['authStatus']),
    loading() {
      return this.authStatus === STATES.LOADING;
    },
    watchSubmission() {
      return this.loading || this.disabled;
    },

    horizontal() {
      return !this.vertical;
    }
  },

  methods: {
    async submit() {
      if (this.$refs.loginForm.validate()) {
        this.disabled = true;
        const { login, password } = this;

        try {
          let payload = {
            login,
            password,
            errorHandler: this.errorHandler,
            remember: true,
          };

          if (this.enableRecaptcha) {
            payload.RC_ResponseToken = await this.recaptchaToken('login');
          }

          const result = await this.$store.dispatch(AUTH_REQUEST, payload);

          this.handleAuthentication();
        } catch (error) {
          captureException(error);
          this.disabled = false;
          this.$log.info('Some error while handling this request, please refresh your page and try again.');
        }
      }
    },

    errorHandler(error) {
      if (error) {
        const { config, request, response, isAxiosError } = error;
        if (isAxiosError) {
          if (typeof response !== 'undefined') {
            let { data } = response;
            let { type, message } = data;

            if ([ErrorType.WS_ERROR, ErrorType.SERVICE_ERROR].includes(type) && message) {
              const regex = /(?<message>[\w]+)([:#\s]+(?<code>\w)+)?/gm;
              const match = regex.exec(message);

              if (match.length > 1 && match.groups.message) {

                if (match.groups.message === ErrorMessage.INVALID_LOGIN_OR_PASSWORD) {
                  const msg = this.$vuetify.lang.t('$vuetify.alerts.invalid_credentials');

                  this.errorMessages.push(msg);

                  this.$log.debug(this.errorMessages);
                  return false;
                }
              }
            }

            if (type === ErrorType.AUTH_ERROR && message === ErrorMessages.NOT_VERIFIED) {
              const msg = this.$vuetify.lang.t('$vuetify.alerts.user_not_verified');
              this.errorMessages.push(msg);
              return false;
            }

            if (type === ErrorType.INPUT_INVALID) {
              const msg = this.$vuetify.lang.t('Invalid input.');
              this.errorMessages.push(msg);
              return false;
            }
          }
        }
      }
      return 'LoginForm Error';
    },

    handleAuthentication() {
      let { redirect } = this.$route.query;

      if (redirect) {
        this.$router.push({ path: redirect });
      } else {
        this.$router.push({ path: process.env.VUE_APP_DEFAULT_REDIRECT });
      }
    },

    submissionWatcher(val, oldVal) {
      if (val !== oldVal) {
        this.$emit('change:submission', val);
      }
    },
  },

  errorCaptured(error) {
    return this.parseError(error);
  },

  watch: {
    watchSubmission: {
      immediate: true,
      handler: 'submissionWatcher',
    },
  },
};
