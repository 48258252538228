import { captureException } from '@sentry/vue';
import Vue from 'vue';

export default {
  props: {
    enableRecaptcha: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      rcDisclaimer: `This site is protected by reCAPTCHA and the Google
    <a class="consent-link text-decoration-none" href="https://policies.google.com/privacy" target="_blank">Privacy Policy</a> and
    <a class="consent-link text-decoration-none" href="https://policies.google.com/terms" target="_blank">Terms of Service</a> apply.`,
    };
  },

  methods: {
    async recaptchaToken(action) {
      if (!this.enableRecaptcha) {
        return false;
      }
      try {
        // (optional) Wait until recaptcha has been loaded.
        await this.$recaptchaLoaded();

        // Execute reCAPTCHA with action "login".
        const token = await this.$recaptcha(action);

        // Do stuff with the received token.
        return token;
      } catch (error) {
        captureException(error);
        Vue.$log.debug(error);
        return false;
      }
    },
  },
};
